<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        ใบขอโอนสินค้าถึง VAN โดยตรง
        <!-- สาขาที่รับสินค้า : {{ branchId }} -->
      </h3>
      <div class="card-toolbar">
        <!-- <button
          type="button"
          class="btn btn-sm btn-light btn-success btn-hover-scale me-2"
          style="font-size: 14px; font-weight: bold"
          @click="gotoImportExcel()"
        >
          นำข้อมูลเข้าจาก Excel
        </button> -->
        <vue3-json-excel
          :json-data="dataItemsShow"
          :fields="json_fields"
          type="xls"
          name="ข้อมูลใบโอนสินค้าระหว่างคลังถึงvan.xls"
          class="me-2"
        >
          <button
            class="btn btn-sm btn-light btn-success"
            style="font-size: 14px; font-weight: bold"
          >
            <span class="svg-icon svg-icon-2x dark">
              <inline-svg src="media/Excel.svg" />
              Download Excel
            </span>
          </button>
        </vue3-json-excel>
        <button
          v-if="userAuth.isCanCreate"
          type="button"
          class="btn btn-sm btn-light btn-primary"
          style="font-size: 14px; font-weight: bold"
          @click="goToNew()"
        >
          <span class="svg-icon svg-icon-2x">
            <inline-svg src="media/Flatten.svg" /> </span
          >{{ $t("addtransferslip") }}
        </button>
      </div>
    </div>
    <div class="card-title row ms-5 me-5 mt-6">
      <div class="col-sm-3 mb-3">
        <input :placeholder="$t(`search`)" type="text" class="form-control" />
      </div>
      <div class="col-sm-1 text-end">
        <button
          type="button"
          class="btn btn-sm btn-light btn-primary"
          style="font-size: 14px; font-weight: bold"
        >
          {{ $t("search") }}
        </button>
      </div>
    </div>
    <div class="card-body" style="padding-top: 3px">
      <div class="table-responsive">
        <a-table :dataSource="dataItemsShow" :columns="columns">
          <template #headerCell="{ column }">
            <template v-if="column.dataIndex === 'no'">
              {{ $t("no") }}
            </template>
            <template v-if="column.dataIndex === 'transaction_number'">
              {{ $t("transactionnumber") }}
            </template>
            <template v-if="column.dataIndex === 'form_warehouse_id'">
              {{ $t("fromwerehouse") }}
            </template>
            <template v-if="column.dataIndex === 'to_van_id'">
              {{ $t("tovan") }}
            </template>
            <template v-if="column.dataIndex === 'remark'">
              {{ $t("note") }}
            </template>
            <template v-if="column.dataIndex === 'status'">
              {{ $t("status") }}
            </template>
            <template v-if="column.dataIndex === 'createdAt'">
              {{ $t("date") }}
            </template>
          </template>

          <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex === 'no'">
              {{ record.no }}
            </template>
            <template v-if="column.dataIndex === 'form_warehouse_id'">
              {{ formatWarehouse(record.form_warehouse_id) }}
              <!-- {{ record.form_warehouse_id }} -->
            </template>
            <template v-if="column.dataIndex === 'to_van_id'">
              {{ formatVan(record.to_van_id) }}
              <!-- {{ record.to_van_id }} -->
            </template>

            <template v-if="column.dataIndex === 'createdAt'">
              <span v-if="record.createdAt">
                {{ record.createdAt ? record.createdAt.substring(0, 10) : "" }}
              </span>
            </template>
            <template v-if="column.dataIndex === 'route'">
              <span v-if="record.route">
                {{ record.route ? record.route.code : "" }} |
                {{ record.route ? record.route.name : "" }}
              </span>
            </template>
            <template v-if="column.dataIndex === 'status'">
              <span
                v-if="record.status == 'รับสินค้าแล้ว'"
                style="color: green"
                >{{ record.status }}</span
              >
              <span v-else style="color: red">{{ record.status }}</span>
            </template>
            <template v-if="column.dataIndex === 'action'">
              <div class="row">
                <div
                  class="col-sm-6 d-flex justify-content-md-end"
                  v-if="userAuth.isCanModified"
                >
                  <button
                    type="button"
                    class="btn btn-link btn-color-muted btn-active-color-success p-0"
                    @click="goToEdit(record.id)"
                  >
                    <i class="bi bi-pencil-square"></i>
                  </button>
                </div>
                <!-- <div class="col-sm-6" v-if="userAuth.isCanDelete">
                  <button
                    @mouseenter="hoverBBtn"
                    type="button"
                    class="btn btn-link btn-color-muted btn-active-color-danger p-0"
                    @click="goToDelete(record, index)"
                  >
                    <i class="bi bi-trash3-fill"></i>
                  </button>
                </div> -->
              </div>
            </template>
          </template>
        </a-table>

        <!-- <table
          class="table table-hover table-rounded table-striped border gy-7 gs-7"
        >
          <thead>
            <tr
              style="width: 100%"
              class="
                fw-bold
                fs-5
                text-gray-800
                border-bottom-2 border-gray-200
                mw-200px
              "
            >
              <th>{{ $t("no") }}</th>
              <th>{{ $t("transactionnumber") }}</th>
              <th>{{ $t("fromwerehouse") }}</th>
              <th>{{ $t("tovan") }}</th>
              <th>{{ $t("note") }}</th>
              <th>{{ $t("date") }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="dataItemsShow.length < 1">
              <td class="text-center" colspan="12">ไม่มีข้อมูล</td>
            </tr>
            <tr v-for="(item, index) in dataItemsShow" :key="index">
              <td>{{ !showing1 ? index + 1 : showing1 + index }}</td>
              <td>{{ item.transaction_number }}</td>
              <td>{{ formatWarehouse(item.form_warehouse_id) }}</td>
              <td>{{ formatVan(item.to_van_id) }}</td>
              <td>{{ item.remark }}</td>
              <td>{{ item.status }}</td>
              <td>{{ formatDate(item.createdAt) }}</td>

              <td></td>
              <td></td>

              <td>
                <div>
                  <button
                    type="button"
                    class="
                      btn btn-link btn-color-muted btn-active-color-success
                      me-sm-2
                      p-0
                    "
                    @click="goToEdit(item.id)"
                  >
                    <i class="bi bi-pencil-square"></i>
                  </button>

                  <button
                    type="button"
                    class="
                      btn btn-link btn-color-muted btn-active-color-danger
                      m-0
                      p-0
                    "
                    @click="goToDelete(item, index)"
                  >
                    <i class="bi bi-trash3-fill"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table> -->
      </div>
    </div>

    <DialogConfirmByPass
      :dialogConfirmByPass="dialogConfirmByPass"
      @submitConfirmPass="submitConfirmPass"
      @closeDialogConformByPass="closeDialogConformByPass"
    />

    <!-- <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div> -->
  </div>
</template>

<script>
import DialogConfirmByPass from "../../components/dialogConfirmByPass.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import transferVANApi from "@/api/transfer";
import moment from "moment";
import whApi from "@/api/warehouse/";

import userApi from "@/api/user/";
import { Decode } from "@/services";

export default {
  components: {
    DialogConfirmByPass,
  },
  setup() {
    document.title = "J&N | สร้างใบรับสินค้าออก";
  },
  data: () => ({
    json_fields: {
      หมายเลขใบโอนสินค้า: "transaction_number",
      จากคลัง: "form_warehouse_id",
      ถึงvan: "to_van_id",
      โน๊ต: "remark",
      สถานะ: "status",
      วันเวลา: "createdAt",
    },

    columns: [
      { title: "no", dataIndex: "no" },
      { title: "transactionnumber", dataIndex: "transaction_number" },
      { title: "fromwerehouse", dataIndex: "form_warehouse_id" },
      { title: "tovan", dataIndex: "to_van_id" },
      { title: "note", dataIndex: "remark" },
      { title: "status", dataIndex: "status" },
      { title: "date", dataIndex: "createdAt" },
      { title: "", dataIndex: "action" },
    ],

    isApprove: false,
    dialogConfirmByPass: false,
    dataItemsShow: [],
    tableItems: [],
    showing1: null,
    warehouses: [],
    vans: [],
    userAuth: [],
    userLocal: [],
    masterMenuId: 16,
  }),
  async created() {
    this.checkMenu();
    await this.getAllWarehouse();
    await this.getAllVan();
    await this.getAll();
  },
  methods: {
    async checkMenu() {
      this.userLocal = JSON.parse(
        Decode.decode(localStorage.getItem("user_temp"))
      );

      console.log("this.userLocal", this.userLocal);

      let responseData = [];
      try {
        responseData = await userApi.user.checkAuth(
          this.userLocal.userGroupId,
          this.masterMenuId
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.userAuth = responseData.data[0];
        console.log("userAuth", this.userAuth);
        if (!this.userAuth.isCanView) {
          // Swal.fire({
          //   icon: "error",
          //   title: "ไม่มีสิทธิ์เข้าใช้งาน",
          //   text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
          // });
          this.$router.push("/404");
        }
      } else {
      }
    },
    async getAll() {
      let responseData = [];
      try {
        responseData = await transferVANApi.transferDIRECTtoVAN.getAll(
          localStorage.getItem("companyId")
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.dataItemsShow = responseData.data;
        this.dataItemsShow.forEach((element, index) => {
          element.no = index + 1;
        });
        console.log("this.dataItemsShow", this.dataItemsShow);
        // this.getProductBywarehouseId();
      } else {
        Swal.fire({
          icon: "error",
          title: `ไม่พบข้อมูล `,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async getAllWarehouse() {
      let responseData = [];
      try {
        responseData = await whApi.warehouse.getAll(
          localStorage.getItem("branchId")
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.warehouses = responseData.data;
        console.log("this.dataItemsShow", this.dataItemsShow);
        // this.getProductBywarehouseId();
      }
    },
    async getAllVan() {
      let responseData = [];
      try {
        responseData = await whApi.van.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.vans = responseData.data;
      }
    },

    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end
    goToNew() {
      this.$router.push("/sale/SA4-1DIRECT/new");
    },
    goToEdit(id) {
      this.$router.push(
        `/sale/SA4-1DIRECT/edit?id=${id}`
        //   {
        //   name: "WH4-edit",
        //   query: {
        //     id: "123",
        //   },
        // }
      );
    },
    gotoImportExcel() {
      this.$router.push("/warehouse/WH4/importexcel");
    },
    async goToDelete(item, index) {
      Swal.fire({
        icon: "question",
        title: `${this.$t("want2delete")}  ${this.$t("ornot")} ?`,
        confirmButtonText: `${this.$t("ok")} `,
        cancelButtonText: `${this.$t("cancel")} `,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.confirmDelete(item, index);
        }
      });
    },
    async confirmDelete(item, index) {
      let deleteResponse = [];
      deleteResponse = await transferVANApi.transferDIRECTtoVAN.delete(
        item.id
      );
      if (deleteResponse.response_status === "SUCCESS") {
        Swal.fire({
          icon: "success",
          title: `${this.$t("deletesuccessfull")} `,
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          this.dataItemsShow.splice(index, 1);
          this.tableItems.splice(index, 1);
          this.getAll(1);
        });
      }
    },
    formatDate(date) {
      if (date) {
        const oldDate = moment(date).format("DD/MM/YYYY - HH:mm:ss น.");
        return oldDate;
      } else {
        return "-";
      }
    },
    formatWarehouse(warehouseId) {
      if (warehouseId) {
        const result = this.warehouses.filter(
          (element) => warehouseId == element.id
        );
        console.log("result", result[0].warehouse_full_name);
        return result[0].warehouse_full_name;
      } else {
        return "-";
      }
    },
    formatVan(vanId) {
      if (vanId) {
        const result = this.vans.filter((element) => vanId == element.id);
        console.log("result", result[0].van_name);
        return result[0].van_name;
      } else {
        return "-";
      }
    },

    handleApprove() {
      this.dialogConfirmByPass = true;
    },
    submitConfirmPass() {
      this.isApprove = true;
      this.dialogConfirmByPass = false;
    },
    closeDialogConformByPass() {
      this.isApprove = false;
      this.dialogConfirmByPass = false;
    },
  },
};
</script>
